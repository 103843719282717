import request from '@/utils/request'
// 咨询师简历

// 获取咨询师简历信息
export function getResume(id,params) {
    return request({
      url: `/reserve/${id}`,
      method: 'get',
      params
    });
}
// 修改咨询师简历信息
export function updateResume(data) {
    return request({
      url: '/reserve/consultantInfo',
      method: 'put',
      data
    });
}