<template>
  <div class="index">
    <!-- 咨询师简历 -->
    <div class="container">
      <div class="picture">
        <!-- <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
          :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
          <img v-if="ConsultantInfo.portrait" :src="ConsultantInfo.portrait" class="avatar">
          <span class="img" v-else>未上传</span>
        </el-upload> -->
        <el-upload class="avatar-uploader" :action="$store.state.requestApi + '/gauge/upload'" :data="{
                id: ConsultantId,
                type: 3
              }" :headers="{ token }" :show-file-list="false"  :on-success="handleAvatarSuccess">
                <img v-if="ConsultantInfo.portrait" :src="ConsultantInfo.portrait" class="avatar">
                <span class="img" v-else>未上传</span>
              </el-upload>
        <!-- <span class="img">头像</span> -->
        <!-- <img src="@/assets/topLogo.png" alt=""> -->
      </div>
      <div class="basic">
        <p class="title">基础信息</p>
        <div class="item">
          <span class="label">登录名：</span>
          <span>{{ConsultantInfo.username}}</span>
          <!-- <el-input placeholder="请输入内容" v-model="value" clearable size="small">
          </el-input> -->
        </div>
        <div class="item">
          <span class="label">姓名：</span>
          <el-input placeholder="请输入内容" @blur="updateInfo" v-model="ConsultantInfo.nickname" clearable size="small">
          </el-input>
        </div>
        <div class="item">
          <span class="label">出生日期：</span>
          <!-- <el-date-picker @change="updateInfo" v-model="ConsultantInfo.birthday" type="date" placeholder="选择日期" format="yyyy年MM月dd日"
      value-format="yyyy-MM-dd">
          </el-date-picker> -->
          <el-date-picker :picker-options="pickerOptions"  v-model="ConsultantInfo.birthday" @change="updateInfo" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"
                        format="yyyy 年 MM 月 dd 日">
                    </el-date-picker>
        </div>
        <div class="item">
          <span class="label">性别：</span>
          <el-select @change="updateInfo" v-model="ConsultantInfo.sex" placeholder="请选择" size="small">
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="2"></el-option>
            <el-option label="未设置" :value="3"></el-option>
          </el-select>
          <!-- <el-input placeholder="请输入内容" v-model="value" clearable size="small">
          </el-input> -->
        </div>
        <div class="item">
          <span class="label">角色：</span>
          <span>{{ConsultantInfo.roleName}}</span>
          <!-- <el-input placeholder="请输入内容" v-model="value" clearable size="small">
          </el-input> -->
        </div>
        <div class="item">
          <span class="label">组织机构：</span>
          <span>{{ConsultantInfo.deptName}}</span>
        </div>
        <div class="item">
          <span class="label">注册时间：</span>
          <span>{{ConsultantInfo.createTime}}</span>
        </div>
        
      </div>
      <div class="basic">
        <p class="title">个人信息</p>
        <div class="item">
          <span class="label">职称：</span>
          <el-input placeholder="请输入职称" @blur="updateInfo" v-model="ConsultantInfo.title" clearable size="small">
          </el-input>
        </div>
        <div class="item" v-if="settingInfo.reserveIsPayment === true">
          <span class="label">预约费用(￥)：</span>
          <el-input placeholder="请输入金额" @blur="updateInfo" v-model="ConsultantInfo.price" oninput="value=value.replace(/[^0-9.]/g,'')"  size="small">
          </el-input>
        </div>
        <div class="item">
          <span class="label">个人简介：</span>
          <el-input type="textarea" @blur="updateInfo" :autosize="{ minRows: 2, maxRows: 4 }" maxlength="200" placeholder="可输入200个字符" v-model="ConsultantInfo.memo">
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getResume, updateResume} from '@/api/consultation/resume'
import { getSetting } from '@/api/settings'
export default {
  data() {
    return {
      // value: null,
      // sex: '1',
      // imageUrl: '',
      ConsultantId: null, // 咨询师id
      token: null, // token
      ConsultantInfo: {

      },
      settingInfo: {},
      pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6; // 如果没有后面的-8.64e6就 是不可以选择今天的
                }
            },
    }
  },
  methods: {
    // 上传图片
    handleAvatarSuccess(res, file) {
      console.log(res)
      if(res.code === 200) {
        this.ConsultantInfo.portrait = res.data;
        this.updateInfo();
      }
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 获取配置信息
    async getLogs() {
      const res = await getSetting();
      console.log(res);
      if (res.code === 200) {
        this.settingInfo = res.data;
      }
    },
    // 获取咨询师信息
    async getInfo() {
      const res = await getResume(JSON.parse(sessionStorage.getItem('userInfo')).id);
      console.log(res);
      if(res.code === 200) {
        this.ConsultantInfo = res.data
      }
    },
    // 修改咨询师简历
    async updateInfo() {
      let info = {
        userId: JSON.parse(sessionStorage.getItem('userInfo')).id,
        nickname: this.ConsultantInfo.nickname,
        birthday: this.ConsultantInfo.birthday,
        sex: this.ConsultantInfo.sex,
        title: this.ConsultantInfo.title,
        price: this.ConsultantInfo.price,
        memo: this.ConsultantInfo.memo,
        portrait: this.ConsultantInfo.portrait
      }
      const res = await updateResume(JSON.stringify(info));
      console.log(res);
      if(res.code === 200) {
        this.$message({
          showClose: true,
          message: '修改成功！',
          type: 'success'
        });
      } else {
        this.$message({
          showClose: true,
          message: '修改失败！' + res.msg,
          type: 'error'
        });
      }
      this.getInfo();
    }

  },
  created() {
    this.ConsultantId = JSON.parse(sessionStorage.getItem('userInfo')).id;
    this.token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    this.getInfo();
    this.getLogs();
  }
}
</script>

<style lang="scss" scoped>
.index {
  padding: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  p {
    margin: 0px;
    padding: 0px;
  }

  .container {
    padding: 20px;
    margin: 0 auto;
    width: 850px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    box-shadow: 0px 2px 4px 0px rgb(229 0 74 / 30%);

    .picture {

      // width: 100%;
      display: flex;
      justify-content: center;
      .img {
        // margin: auto;
        width: 130px;
        height: 130px;
        display: block;
        border-radius: 15px;
        // background-color: antiquewhite;
        box-shadow: 1px 1px 1px 1px rgb(189, 188, 188);
      }
      img{
        width: 130px;
        height: 130px;
        display: block;
        border-radius: 15px;
      }

    }

    .basic {
      .title {
        font-size: 16px;
        font-weight: 600;
      }

      .item {
        margin-top: 20px;

        // padding-left: 150px;
        .label {
          display: inline-block;
          width: 200px;
          text-align: right;
        }

        .el-input,
        .el-textarea,
        .el-select {
          width: 400px;
        }
      }
    }
  }
}
</style>